import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Topbar = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHeader {
        topBar {
          contactData {
            url
            icon
            label
          }
          socialMediaLink {
            url
            icon
            label
          }
        }
      }
    }
  `)

  const contact = data.contentfulHeader.topBar[0].contactData;
  const socialLink = data.contentfulHeader.topBar[0].socialMediaLink;

  return (
    <div className="header-top">
      <div className="auto-container">
        <div className="inner-container clearfix">
          <div>
            <ul className="info-list">
              {contact.map(({ url, icon, label }, index) => (
                <li key={index}>
                  <a href={url}>
                    <span className={icon} /> {label}
                  </a>
                </li>
              ))
              }
            </ul>
          </div>
          <div>
            <ul className="social-box">
              {socialLink.map(({ url, icon }, index) => (
                <li key={index}>
                  <a href={url} target="_blank" rel="noreferrer">
                    <i className={icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Topbar;
