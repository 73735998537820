
import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO() {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>TekkiFii- Economic Development Programme</title>
      <meta name="description" content="Consulte is a free Bootstrap HTML Template for Investment Company" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="profile" href="https://gmpg.org/xfn/11" />
      <link rel="canonical" href="Replace_with_your_PAGE_URL" />
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      <link href="css/main.css" rel="stylesheet" />
      <link href="css/responsive.css" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
      <link rel="shortcut icon" href="images/favicon.png" type="image/x-icon" />
      <link rel="icon" href="images/favicon.png" type="image/x-icon" />
      <meta name="msapplication-TileImage" content="images/favicon.png" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO