import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    color: #777;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    overflow-x: hidden;
    line-height: 1.7em;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-family: 'Work Sans', sans-serif !important;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: #d00223;
  }

  button,
    a:hover,a:focus,a:visited {
    text-decoration:none;
    outline:none !important;
  }

  h1,h2,h3,h4,h5,h6 {
    margin:0px;
    background:none;
    position:relative;
    line-height:1.6em;
    font-weight:normal;
    font-family: 'Work Sans', sans-serif !important;
  }

  h1 {
    font-size: 60px;
  }
  
  h2 {
    font-size: 48px;
  }
  
  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }
  
  h5 {
    font-size: 20px;
  }
  
  h6 {
    font-size: 18px;
  }
`;