import * as React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'react-multi-carousel/lib/styles.css';
import 'slick-carousel/slick/slick-theme.css';

import './css/main.css';
import './css/layout.css';
import './css/responsive.css';
import './css/bootstrap.min.css';

import Header from './header/header';
import Footer from './footer/footer';
import { GlobalStyles } from './theme/GlobalStyles';

const Layout = ({ children }) => {
  const theme = {
    borderColor: '',
    primaryColor: '',
    secondaryColor: '',
  };
 

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header/>
        <main>{children}</main>
      <Footer/>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

