import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulFooter {
      logos {
        partnerLogos {
          file {
            url
          }
          title
        }
      }
      footerContact {
        title
        contactNumber
        contactNumberUrl
        email
        emailUrl
      }
      quickLinkTitle
      socialMediaTitle
      socialMedia {
        label
        name
        url
      }
      newsletter {
        newsletterTitle
        newsletterDescription
        newsletterButtonName
        newsletterLink {
          newsletterLink
        }
      }
    }
    contentfulHeader {
      navigationElement {
        slug
        title
      }
    }
  }
`)

  const contact = data.contentfulFooter.footerContact;
  const newsletterInfo = data.contentfulFooter.newsletter;
  const Media = data.contentfulFooter.socialMedia;
  const footerLogos = data.contentfulFooter.logos.partnerLogos;
  const navItems = data.contentfulHeader.navigationElement;

  return (
    <footer className="main-footer">
      <div className="auto-container">
        <div className="widgets-section">
          <div className="row clearfix">
            <div className="big-column col-lg-6 col-md-12 col-sm-6 col-12">
              <div className="row clearfix">
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget logo-widget">
                    <div className="logo mb-2">
                      {footerLogos.map(({ file, title }, index) => (
                        <Link href="/" key={index}>
                          <img src={file.url} alt={title} />
                        </Link>
                      ))}
                    </div>
                    <div className="call">
                      {contact.title}
                      <a className="phone" href={contact.contactNumberUrl}>{contact.contactNumber}</a>
                      <a className="email" href={contact.emailUrl}>{contact.email}</a>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5>{data.contentfulFooter.quickLinkTitle}</h5>
                    <ul className="list-link">
                      {navItems.map(({ title, slug }, index) => (
                        <li key={index}><a href={slug}>{title}</a></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="big-column col-lg-6 col-md-12 col-sm-6 col-12">
              <div className="row clearfix">
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5>{data.contentfulFooter.socialMediaTitle}</h5>
                    <ul className="list-link">
                      {Media.map(({ label, url }, index) => (
                        <li key={index}><a target="_blank" rel="noreferrer" href={url}>{label}</a></li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget newsletter-widget">
                    <h5>{newsletterInfo.newsletterTitle}</h5>
                    <div className="text">{newsletterInfo.newsletterDescription}</div>
                    <div className="newsletter-form">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={newsletterInfo.newsletterLink.newsletterLink}
                        className="theme-btn btn-style-one">
                        <span className="txt">{newsletterInfo.newsletterButtonName}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="bottom-inner">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="copyright text-center">© Copyright {new Date().getFullYear()}, All Rights Reserved | Crafted with <i className="icon icofont-heart" aria-label="Heart icon"></i> by <a href="https://assutech.gm/" rel="noreferrer" target="_blank">Assutech</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
