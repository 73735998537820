import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Sticky from 'react-stickynode';
import Topbar from './topbar';
import { useStaticQuery, graphql } from "gatsby"

const activeStyle = {
  color: '#d00223',
};
const Header = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHeader {
        logo {
          file {
            url
          }
        }
        navigationElement {
          slug
          title
        }
      }
    }
  `)
  const navItems = data.contentfulHeader.navigationElement;
  console.log(navItems);
  const logo = data.contentfulHeader.logo.file;
  return (
    <>
      <Topbar />
      <Sticky enabled={true} innerZ="9999">
        <header className="main-header">
          <div className="header-upper">
            <div className="auto-container">
              <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid bg-white p-0">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to="/" className="navbar-brand">
                        <img src={logo.url} alt="logo" />
                      </Link>
                    </div>
                  </div>
                  <button
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="collapse"
                    className="navbar-toggler"
                    aria-label="Toggle navigation"
                    aria-controls="navbarSupportedContent"
                    data-bs-target="#navbarSupportedContent"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-0">
                      <li className="nav-item mb-0">
                        <Link to="/" className="nav-link" activeStyle={activeStyle}>Home</Link>
                      </li>
                      {
                        navItems.map(({ slug, title }, index) => (
                          <li key={index} className="nav-item mb-0">
                            <Link to={`/${slug}`} className="nav-link" activeStyle={activeStyle}>
                              {title}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </Sticky>
    </>
  )

};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
